var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          ref: "installationTrigger",
          staticClass: "d-flex align-content-center text-whale",
          attrs: { variant: "outline" }
        },
        [
          _c("span", [
            _vm._v(
              _vm._s(_vm.$authService.getInstallation().getInstallationName())
            )
          ]),
          _c("b-icon-chevron-right", {
            class: {
              "ml-3": true,
              "picker-chevron": true,
              open: _vm.isOpen
            }
          })
        ],
        1
      ),
      _c(
        "infinity-popover",
        {
          attrs: {
            target: _vm.$refs.installationTrigger,
            "custom-class": "installation-picker"
          },
          on: {
            shown: function($event) {
              _vm.isOpen = true
            },
            hidden: function($event) {
              _vm.isOpen = false
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "p-2" },
            [
              _c("b-input", {
                staticClass: "w-100",
                attrs: {
                  type: "search",
                  debounce: "250",
                  placeholder: "Find by name"
                },
                on: { update: _vm.doSearch },
                model: {
                  value: _vm.searchQuery,
                  callback: function($$v) {
                    _vm.searchQuery = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "searchQuery"
                }
              })
            ],
            1
          ),
          _c("hr", { staticClass: "mt-1 mb-0" }),
          _vm.searchQuery
            ? [
                _c(
                  "b-list-group",
                  { staticClass: "installation-picker--results" },
                  [
                    _vm.installations.length
                      ? _vm._l(_vm.installations, function(installation) {
                          return _c(
                            "b-list-group-item",
                            {
                              key: installation.getInstallationId(),
                              staticClass: "d-flex align-items-center",
                              attrs: { button: "" },
                              on: {
                                click: function($event) {
                                  return _vm.doSelectInstallation(installation)
                                }
                              }
                            },
                            [
                              _c("h6", { staticClass: "m-0" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(installation.getInstallationName()) +
                                    " "
                                )
                              ])
                            ]
                          )
                        })
                      : _vm.isLoading
                      ? _c(
                          "b-list-group-item",
                          { staticClass: "text-center" },
                          [_c("b-spinner", { attrs: { small: "" } })],
                          1
                        )
                      : _c("b-list-group-item", [
                          _vm._v(
                            " Could not find an Installation with that name "
                          )
                        ])
                  ],
                  2
                ),
                _c("hr", { staticClass: "m-0" })
              ]
            : _vm._e(),
          _c("h6", { staticClass: "p-2 m-0 text-bold" }, [_vm._v("Starred")]),
          _c("hr", { staticClass: "m-0" }),
          _c(
            "b-list-group",
            [
              _vm.starredInstallations.length
                ? _vm._l(_vm.starredInstallations, function(installation) {
                    return _c(
                      "b-list-group-item",
                      {
                        key: installation.getInstallationId(),
                        staticClass: "d-flex align-items-center",
                        attrs: { button: "" },
                        on: {
                          click: function($event) {
                            return _vm.doSelectInstallation(installation)
                          }
                        }
                      },
                      [
                        _c("b-icon-star-fill", { staticClass: "mr-3" }),
                        _c("h6", { staticClass: "m-0" }, [
                          _vm._v(
                            " " +
                              _vm._s(installation.getInstallationName()) +
                              " "
                          )
                        ])
                      ],
                      1
                    )
                  })
                : _vm.isStarredLoading
                ? _c(
                    "b-list-group-item",
                    { staticClass: "text-center" },
                    [_c("b-spinner", { attrs: { small: "" } })],
                    1
                  )
                : _c("b-list-group-item", [
                    _vm._v(" No starred installations available ")
                  ])
            ],
            2
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }