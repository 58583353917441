var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vw-100 vh-100 bg-infinity", attrs: { id: "login" } },
    [
      _c(
        "b-container",
        { staticClass: "w-100 h-100" },
        [
          _c(
            "b-row",
            { staticClass: "w-100 h-100" },
            [
              _c(
                "b-col",
                { staticClass: "m-auto", attrs: { id: "loginCol", cols: "5" } },
                [
                  _c(
                    "b-overlay",
                    { attrs: { show: _vm.isLoading } },
                    [
                      _c(
                        "b-card",
                        { staticClass: "shadow-lg border-0 p-5" },
                        [
                          _c(
                            "div",
                            { staticClass: "mb-4" },
                            [_c("HubLogo")],
                            1
                          ),
                          _c("b-card-title", [_vm._v("Login")]),
                          _c(
                            "b-form",
                            {
                              on: {
                                submit: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.onSubmit($event)
                                }
                              }
                            },
                            [
                              _c(
                                "transition",
                                { attrs: { name: "fade", mode: "out-in" } },
                                [
                                  _vm.mfaRequired
                                    ? _c(
                                        "div",
                                        { key: "mfa" },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                id: "password-group",
                                                description:
                                                  "Enter your 6-digit verification code",
                                                label: "2 Step Verification",
                                                "label-for": "verificationCode",
                                                state: _vm.formState
                                              }
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "verificationCode",
                                                  state: _vm.mfaState,
                                                  trim: ""
                                                },
                                                model: {
                                                  value: _vm.verificationCode,
                                                  callback: function($$v) {
                                                    _vm.verificationCode = $$v
                                                  },
                                                  expression: "verificationCode"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-form-invalid-feedback",
                                            { attrs: { state: _vm.mfaState } },
                                            [
                                              _vm._v(
                                                " Your verification code was not recognised. Please try again. "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "d-flex mt-3" },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    type: "reset",
                                                    variant: "outline-danger",
                                                    disabled: _vm.isLoading
                                                  },
                                                  on: { click: _vm.onReset }
                                                },
                                                [_vm._v("Reset")]
                                              ),
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass: "ml-auto",
                                                  attrs: {
                                                    type: "submit",
                                                    variant: "success",
                                                    disabled: _vm.isLoading
                                                  }
                                                },
                                                [_vm._v("Login")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        { key: "login" },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                id: "username-group",
                                                description:
                                                  "Enter the username you use to login to the Hub.",
                                                label: "Username",
                                                "label-for": "username",
                                                state: _vm.formState
                                              }
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "username",
                                                  state: _vm.formState,
                                                  trim: ""
                                                },
                                                model: {
                                                  value: _vm.username,
                                                  callback: function($$v) {
                                                    _vm.username = $$v
                                                  },
                                                  expression: "username"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                id: "password-group",
                                                description:
                                                  "Enter the password you use to login to the Hub.",
                                                label: "Password",
                                                "label-for": "password",
                                                state: _vm.formState
                                              }
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "password",
                                                  type: "password",
                                                  state: _vm.formState,
                                                  trim: ""
                                                },
                                                model: {
                                                  value: _vm.password,
                                                  callback: function($$v) {
                                                    _vm.password = $$v
                                                  },
                                                  expression: "password"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-form-invalid-feedback",
                                            { attrs: { state: _vm.formState } },
                                            [
                                              _vm._v(
                                                " We couldn't verify your username or password. Try a different combination, or visit the Hub to reset your password. "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "d-flex mt-3" },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass: "ml-auto",
                                                  attrs: {
                                                    type: "submit",
                                                    variant: "success",
                                                    disabled: _vm.isLoading
                                                  }
                                                },
                                                [_vm._v("Login")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }