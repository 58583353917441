var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "fade", mode: "out-in" } },
    [
      !_vm.hasBooted
        ? _c("Loader")
        : [
            _c(
              "div",
              { staticClass: "chrome-container" },
              [
                _vm.hasBooted
                  ? _c(
                      "b-navbar",
                      {
                        attrs: { id: "header", variant: "white", type: "light" }
                      },
                      [
                        _c(
                          "b-navbar-brand",
                          { attrs: { href: "/" } },
                          [_c("HubLogo")],
                          1
                        ),
                        _c(
                          "b-navbar-nav",
                          { staticClass: "ml-3" },
                          [_c("b-nav-form", [_c("InstallationPicker")], 1)],
                          1
                        ),
                        _c(
                          "b-navbar-nav",
                          { staticClass: "ml-auto" },
                          [
                            _c(
                              "b-nav-item-dropdown",
                              {
                                attrs: { right: "", id: "profile" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "button-content",
                                      fn: function() {
                                        return [
                                          _c(
                                            "b-avatar",
                                            { attrs: { rounded: "" } },
                                            [
                                              _c("img", {
                                                staticClass: "avatar-img",
                                                attrs: {
                                                  src: _vm.$authService
                                                    .getProfile()
                                                    .getGravatarUrl(),
                                                  alt: "avatar"
                                                }
                                              }),
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "avatar-initials"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$authService
                                                        .getProfile()
                                                        .getInitials()
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  1312486475
                                )
                              },
                              [
                                _c("b-dropdown-text", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$authService
                                        .getProfile()
                                        .getUsername()
                                    )
                                  )
                                ]),
                                _c(
                                  "b-dropdown-item",
                                  { on: { click: _vm.profileDetails } },
                                  [_vm._v("My Profile")]
                                ),
                                _c(
                                  "b-dropdown-item",
                                  { on: { click: _vm.logout } },
                                  [_vm._v("Sign Out")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "chrome-content" },
                  [_c("router-view")],
                  1
                )
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }