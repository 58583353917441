var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-container",
        { staticClass: "d-flex align-items-center flex-column" },
        [
          _c(
            "b-card",
            {
              staticClass: "mt-5",
              attrs: { id: "titleCard", title: "Profile" }
            },
            [
              _c("b-card-text", [
                _vm._v("Username: " + _vm._s(_vm.profile.getUsername()))
              ]),
              _c("b-card-text", [
                _vm._v(
                  "Name: " +
                    _vm._s(_vm.profile.getFirstName()) +
                    " " +
                    _vm._s(_vm.profile.getLastName())
                )
              ]),
              _c("b-card-text")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }